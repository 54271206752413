import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as faceapi from 'face-api.js';
import Matter from 'matter-js';

const HeadTracking = () => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const engineRef = useRef(null);
    const renderRef = useRef(null);
    const runnerRef = useRef(null);
    const gameObjectsRef = useRef({});

    const [isTracking, setIsTracking] = useState(false);
    const [status, setStatus] = useState('等待开始...');
    const [score, setScore] = useState(0);
    const [lives, setLives] = useState(3);
    const [gameStarted, setGameStarted] = useState(false);
    const [highScore, setHighScore] = useState(
        parseInt(localStorage.getItem('brickBreaker_highScore') || '0')
    );

    const playerRef = useRef({
        x: 300,
        y: 200,
        radius: 25,
        color: '#4CAF50',
        speed: 0.3
    });

    const isTrackingRef = useRef(false);

    // 游戏配置常量
    const GAME_CONFIG = {
        canvas: {
            width: 800,
            height: 600,
        },
        ball: {
            radius: 10,
            initialSpeed: 3,
            color: '#4CAF50',
        },
        paddle: {
            width: 150,
            height: 15,
            color: '#2196F3',
            arcHeight: 5,
        },
        bricks: {
            rows: 5,
            padding: 10,
            offsetTop: 50,
            types: [
                { color: '#FF6B6B', gradientColor: '#FF8C8C', score: 1 },
                { color: '#4ECDC4', gradientColor: '#6EEAE2', score: 2 },
                { color: '#45B7D1', gradientColor: '#67D9F3', score: 3 },
                { color: '#FFA07A', gradientColor: '#FFC29C', score: 5 }
            ]
        }
    };

    useEffect(() => {
        const initFaceAPI = async () => {
            try {
                setStatus('正在加载模型...');
                await Promise.all([
                    faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
                    faceapi.nets.faceLandmark68Net.loadFromUri('/models')
                ]);
                setStatus('模型加载完成');
            } catch (error) {
                setStatus('模型加载失败');
                console.error('初始化错误:', error);
            }
        };

        initFaceAPI();
    }, []);

    const trackFace = async () => {
        console.log('执行追踪...', {
            refTracking: isTrackingRef.current,
            stateTracking: isTracking,
            videoReady: !!videoRef.current
        });

        if (!videoRef.current || !isTrackingRef.current) {
            console.warn('追踪终止 - 视频或追踪状态无效', {
                videoReady: !!videoRef.current,
                isTracking: isTrackingRef.current
            });
            return;
        }

        try {
            const detections = await faceapi
                .detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions())
                .withFaceLandmarks();
            
            console.log('检测到的面部:', detections.length);

            if (detections.length > 0 && gameObjectsRef.current.paddle) {
                const landmarks = detections[0].landmarks;
                const nose = landmarks.getNose()[3];
                console.log('Nose position:', nose.x, nose.y);
                
                // 获取视频实际显示尺寸
                const videoWidth = videoRef.current.videoWidth;
                
                // 计算缩放比例，增加 50% 的灵敏度
                const scaleX = (GAME_CONFIG.canvas.width / videoWidth) * 1.5;
                
                // 计算挡板位置，保持在画布范围内
                let paddleX = GAME_CONFIG.canvas.width - (nose.x * scaleX);
                
                // 限制挡板在画布范围内，考虑新的挡板宽度
                paddleX = Math.max(
                    GAME_CONFIG.paddle.width / 2,
                    Math.min(
                        GAME_CONFIG.canvas.width - GAME_CONFIG.paddle.width / 2,
                        paddleX
                    )
                );

                // 设置挡板位置
                Matter.Body.setPosition(gameObjectsRef.current.paddle, {
                    x: paddleX,
                    y: gameObjectsRef.current.paddle.position.y
                });

                // 添加调试日志
                console.log({
                    noseX: nose.x,
                    videoWidth,
                    scaleX,
                    paddleX,
                    currentPaddleX: gameObjectsRef.current.paddle.position.x
                });
            }

            // 使用 ref 来检查追踪状态
            if (isTrackingRef.current) {
                requestAnimationFrame(() => {
                    trackFace().catch(err => {
                        console.error('追踪循环错误:', err);
                    });
                });
            }
        } catch (error) {
            console.error('追踪错误:', error);
            if (isTrackingRef.current) {
                requestAnimationFrame(() => {
                    trackFace().catch(err => {
                        console.error('追踪循环错误:', err);
                    });
                });
            }
        }
    };

    const stopTracking = () => {
        setIsTracking(false);
        if (videoRef.current && videoRef.current.srcObject) {
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }
        setStatus('游戏已停止');
        setScore(0);
    };

    const createBricks = () => {
        const canvas = canvasRef.current;
        const bricks = [];
        const brickTypes = [
            { color: '#FF6B6B', score: 1 },
            { color: '#4ECDC4', score: 2 },
            { color: '#45B7D1', score: 3 },
            { color: '#FFA07A', score: 5 }
        ];

        const brickWidth = (canvas.width - GAME_CONFIG.bricks.padding * (GAME_CONFIG.bricks.rows + 1)) / GAME_CONFIG.bricks.rows;
        const brickHeight = 30;

        for (let r = 0; r < GAME_CONFIG.bricks.rows; r++) {
            for (let c = 0; c < GAME_CONFIG.bricks.rows; c++) {
                const brickType = brickTypes[Math.floor(Math.random() * brickTypes.length)];
                const brick = Matter.Bodies.rectangle(
                    c * (brickWidth + GAME_CONFIG.bricks.padding) + brickWidth / 2 + GAME_CONFIG.bricks.padding,
                    r * (brickHeight + GAME_CONFIG.bricks.padding) + brickHeight / 2 + GAME_CONFIG.bricks.offsetTop,
                    brickWidth,
                    brickHeight,
                    {
                        isStatic: true,
                        label: 'brick',
                        render: {
                            fillStyle: brickType.color
                        },
                        score: brickType.score
                    }
                );
                bricks.push(brick);
            }
        }
        return bricks;
    };

    const setupCollisions = () => {
        let isHandlingBallLost = false;

        Matter.Events.on(engineRef.current, 'afterUpdate', () => {
            const ball = gameObjectsRef.current.ball;
            if (ball && ball.position.y > GAME_CONFIG.canvas.height && !isHandlingBallLost) {
                isHandlingBallLost = true;
                handleBallLost();
                setTimeout(() => {
                    isHandlingBallLost = false;
                }, 1000);
            }
        });

        // 砖块碰撞检测保持不变
        Matter.Events.on(engineRef.current, 'collisionStart', (event) => {
            event.pairs.forEach((pair) => {
                const { bodyA, bodyB } = pair;
                const gameObjects = gameObjectsRef.current;

                // 检查球与砖块的碰撞
                if ((bodyA === gameObjects.ball && bodyB.label === 'brick') ||
                    (bodyB === gameObjects.ball && bodyA.label === 'brick')) {
                    const brick = bodyA.label === 'brick' ? bodyA : bodyB;
                    setScore(prev => prev + brick.score);
                    Matter.World.remove(engineRef.current.world, brick);

                    const remainingBricks = engineRef.current.world.bodies.filter(body => body.label === 'brick');
                    if (remainingBricks.length === 0) {
                        handleWin();
                    }
                }
            });
        });
    };

    const handleBallLost = () => {
        // 确保移除当前的球
        if (gameObjectsRef.current.ball) {
            Matter.World.remove(engineRef.current.world, gameObjectsRef.current.ball);
            gameObjectsRef.current.ball = null;
        }

        setLives(prev => {
            const newLives = prev - 1;
            console.log('剩余生命:', newLives);
            
            if (newLives > 0) {
                // 还有生命就重置球
                setTimeout(() => {
                    if (!gameObjectsRef.current.ball) {
                        resetBall();
                    }
                }, 1000);
                return newLives;
            } else {
                handleGameOver();
                return 0;
            }
        });
    };

    const resetBall = () => {
        // 如果已经存在球，先移除
        if (gameObjectsRef.current.ball) {
            Matter.World.remove(engineRef.current.world, gameObjectsRef.current.ball);
            gameObjectsRef.current.ball = null;
        }

        const paddle = gameObjectsRef.current.paddle;
        
        // 创建新球在挡板上方
        const newBall = Matter.Bodies.circle(
            paddle.position.x,
            paddle.position.y - GAME_CONFIG.paddle.height - GAME_CONFIG.ball.radius - 5,
            GAME_CONFIG.ball.radius,
            {
                label: 'ball',
                render: {
                    fillStyle: GAME_CONFIG.ball.color
                },
                restitution: 1,
                friction: 0,
                frictionAir: 0,
                density: 0.001,
                inertia: Infinity
            }
        );
        
        // 随机左右方向发球
        Matter.Body.setVelocity(newBall, {
            x: GAME_CONFIG.ball.initialSpeed * (Math.random() > 0.5 ? 1 : -1),
            y: -GAME_CONFIG.ball.initialSpeed
        });
        
        Matter.World.add(engineRef.current.world, newBall);
        gameObjectsRef.current.ball = newBall;
    };

    const handleGameOver = () => {
        setStatus('游戏结束');
        // 不停止追踪，让玩家可以继续控制挡板
    };

    const handleWin = () => {
        setStatus('恭喜获胜！');
        stopTracking();
    };

    // 初始化游戏引擎
    const initGame = useCallback(() => {
        const canvas = canvasRef.current;
        
        // 清理之前的引擎（如果存在）
        if (engineRef.current) {
            Matter.Engine.clear(engineRef.current);
        }

        // 创建引擎
        const engine = Matter.Engine.create({
            gravity: { x: 0, y: 0 }
        });

        // 创建渲染器
        const render = Matter.Render.create({
            canvas: canvas,
            engine: engine,
            options: {
                width: GAME_CONFIG.canvas.width,
                height: GAME_CONFIG.canvas.height,
                wireframes: false,
                background: '#f8fafc',
                pixelRatio: 1
            }
        });

        // 创建运行器
        const runner = Matter.Runner.create();

        engineRef.current = engine;
        renderRef.current = render;
        runnerRef.current = runner;

        Matter.Render.run(render);
        Matter.Runner.run(runner, engine);

        console.log('Engine initialized:', engineRef.current);
    }, []);

    // 创建墙壁
    const createWalls = () => {
        const canvas = canvasRef.current;
        const wallThickness = 20;

        // 创建三面墙（左、右、上）
        const walls = [
            // 左墙
            Matter.Bodies.rectangle(
                -wallThickness / 2,
                canvas.height / 2,
                wallThickness,
                canvas.height,
                {
                    isStatic: true,
                    label: 'wall',
                    render: { visible: false }
                }
            ),
            // 右墙
            Matter.Bodies.rectangle(
                canvas.width + wallThickness / 2,
                canvas.height / 2,
                wallThickness,
                canvas.height,
                {
                    isStatic: true,
                    label: 'wall',
                    render: { visible: false }
                }
            ),
            // 上墙
            Matter.Bodies.rectangle(
                canvas.width / 2,
                -wallThickness / 2,
                canvas.width,
                wallThickness,
                {
                    isStatic: true,
                    label: 'wall',
                    render: { visible: false }
                }
            )
        ];

        return walls;
    };

    // 创建挡板
    const createPaddle = () => {
        const canvas = canvasRef.current;
        const paddle = Matter.Bodies.rectangle(
            canvas.width / 2,
            canvas.height - 30,
            GAME_CONFIG.paddle.width,
            GAME_CONFIG.paddle.height,
            {
                isStatic: true,
                label: 'paddle',
                chamfer: { radius: GAME_CONFIG.paddle.arcHeight },
                render: {
                    fillStyle: GAME_CONFIG.paddle.color
                },
                restitution: 1,
                friction: 0,
                frictionAir: 0
            }
        );

        return paddle;
    };

    // 创建球
    const createBall = () => {
        const canvas = canvasRef.current;
        const ball = Matter.Bodies.circle(
            canvas.width / 2,
            canvas.height - 50,
            GAME_CONFIG.ball.radius,
            {
                label: 'ball',
                render: {
                    fillStyle: GAME_CONFIG.ball.color
                },
                restitution: 1,
                friction: 0,
                frictionAir: 0,
                density: 0.001,
                inertia: Infinity
            }
        );

        // 增加初始速度
        Matter.Body.setVelocity(ball, {
            x: GAME_CONFIG.ball.initialSpeed * (Math.random() > 0.5 ? 1 : -1),
            y: -GAME_CONFIG.ball.initialSpeed * 1.5
        });

        return ball;
    };

    // 创建游戏对象
    const createGameObjects = useCallback(() => {
        const engine = engineRef.current;
        if (!engine) return;

        // 创建墙壁
        const walls = createWalls();

        // 创建挡板
        const paddle = createPaddle();

        // 创建球
        const ball = createBall();

        // 创建砖块
        const bricks = createBricks();

        // 存储游戏对象引用
        gameObjectsRef.current = {
            walls,
            paddle,
            ball,
            bricks
        };

        // 添加所有对象到世界
        Matter.World.add(engine.world, [...walls, paddle, ball, ...bricks]);

        console.log('Game objects created:', gameObjectsRef.current);
    }, []);

    // 清理游戏
    const cleanupGame = useCallback(() => {
        if (engineRef.current) {
            Matter.World.clear(engineRef.current.world);
            Matter.Engine.clear(engineRef.current);
        }

        if (renderRef.current) {
            Matter.Render.stop(renderRef.current);
        }

        if (runnerRef.current) {
            Matter.Runner.stop(runnerRef.current);
        }
    }, []);

    // 开始游戏
    const startGame = async () => {
        try {
            // 先设置状态
            isTrackingRef.current = true;
            setIsTracking(true);
            setGameStarted(true);
            setStatus('游戏进行中');
            setLives(3);
            setScore(0);

            // 等待状态更新
            await new Promise(resolve => setTimeout(resolve, 100));

            const stream = await navigator.mediaDevices.getUserMedia({ 
                video: {
                    width: 640,
                    height: 480,
                    facingMode: 'user'
                } 
            });

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                
                // 等待视频加载完成
                await new Promise((resolve) => {
                    videoRef.current.onloadedmetadata = () => {
                        videoRef.current.play();
                        resolve();
                    };
                });
                
                // 初始化游戏
                initGame();
                createGameObjects();
                setupCollisions();
                
                console.log('开始面部追踪...', { 
                    isTracking: isTrackingRef.current,
                    stateTracking: isTracking 
                });
                
                // 使用 ref 来检查状态
                if (isTrackingRef.current) {
                    trackFace().catch(err => {
                        console.error('追踪错误:', err);
                    });
                }
            }
        } catch (error) {
            console.error('游戏启动误:', error);
            setStatus('游戏启动失败');
        }
    };

    // 停止游戏
    const stopGame = () => {
        isTrackingRef.current = false;
        setIsTracking(false);
        if (videoRef.current?.srcObject) {
            videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }

        cleanupGame();
        setStatus('游戏已停止');
        setLives(3); // 重置生命值

        // 更新最高分
        if (score > highScore) {
            setHighScore(score);
            localStorage.setItem('brickBreaker_highScore', score.toString());
        }
    };

    // 修改重启游戏函数，确保追踪继续进行
    const restartGame = () => {
        // 清理当前游戏状态
        cleanupGame();
        
        // 重置游戏状态
        setLives(3);
        setScore(0);
        setStatus('游戏进行中');
        
        // 重新初始化游戏
        initGame();
        createGameObjects();
        setupCollisions();
        
        // 重置球
        resetBall();
    };

    // 更新 UI 样式
    return (
        <section className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12">
            <div className="container mx-auto px-4">
                <div className="max-w-4xl mx-auto">
                    {/* 游戏标题 */}
                    <h1 className="text-4xl font-bold text-center mb-8">
                        头部追踪算法演示 - 打砖块
                    </h1>

                    {/* 游戏状态面板 */}
                    <div className="bg-white rounded-2xl shadow-xl p-8">
                        {/* 分数和生命值 */}
                        <div className="flex justify-between mb-6">
                            <div className="flex space-x-8">
                                <div className="stats-item">
                                    <span className="text-gray-600">得分</span>
                                    <span className="text-2xl font-bold text-blue-600">{score}</span>
                                </div>
                                <div className="stats-item">
                                    <span className="text-gray-600">最高分</span>
                                    <span className="text-2xl font-bold text-purple-600">{highScore}</span>
                                </div>
                                <div className="stats-item">
                                    <span className="text-gray-600">生命</span>
                                    <span className="text-2xl font-bold text-red-600">{lives}</span>
                                </div>
                            </div>
                            <div className="status-badge">
                                <span className={`px-4 py-2 rounded-full ${isTracking ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                                    }`}>
                                    {status}
                                </span>
                            </div>
                        </div>

                        {/* 游戏画布区域 */}
                        <div className="relative aspect-video bg-gray-50 rounded-xl overflow-hidden">
                            <canvas
                                ref={canvasRef}
                                width={GAME_CONFIG.canvas.width}
                                height={GAME_CONFIG.canvas.height}
                                className="w-full h-full"
                            />
                            <video
                                ref={videoRef}
                                className="absolute top-4 right-4 w-48 h-36 rounded-lg shadow-lg"
                                autoPlay
                                playsInline
                                muted
                                style={{ transform: 'scaleX(-1)' }} // 水平翻转视频，使移动方向更直观
                            />
                        </div>

                        {/* 控制按钮 */}
                        <div className="flex justify-center mt-6 space-x-4">
                            <button
                                onClick={isTracking ? stopGame : startGame}
                                className={`
                                    px-8 py-3 rounded-lg font-semibold text-white
                                    transition-all transform hover:scale-105
                                    ${isTracking
                                        ? 'bg-red-500 hover:bg-red-600'
                                        : 'bg-blue-500 hover:bg-blue-600'
                                    }
                                `}
                            >
                                {isTracking ? '停止游戏' : '开始游戏'}
                            </button>
                            
                            {/* 只在游戏结束（生命值为0）且游戏仍在运行时显示重新开始按钮 */}
                            {lives === 0 && isTracking && (
                                <button
                                    onClick={restartGame}
                                    className="px-8 py-3 rounded-lg font-semibold text-white
                                             bg-green-500 hover:bg-green-600
                                             transition-all transform hover:scale-105"
                                >
                                    重新开始
                                </button>
                            )}
                        </div>

                        {/* 游戏状态显示 */}
                        <div className="text-center mt-4">
                            <p className="text-lg font-semibold">
                                生命值: <span className="text-red-500">{lives}</span>
                            </p>
                            {lives === 0 && (
                                <p className="text-xl font-bold text-red-600 mt-2">
                                    游戏结束！
                                </p>
                            )}
                        </div>
                    </div>

                    {/* 游戏说明 */}
                    <div className="mt-8 text-center text-gray-600">
                        <p>使用头部移动来控制挡板，击碎所有砖块获得胜利！</p>
                        <p className="mt-2">不同颜色的砖块有不同的分值，努力获得最高分吧！</p>
                    </div>
                </div>
            </div>

            {/* 自定义样式 */}
            <style>{`
            .stats-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
            }
            `}</style>
        </section>
    );
};

export default HeadTracking; 