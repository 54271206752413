import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#111] text-[#f0f0f0] py-8 text-center">
      <p>© 2024 杭州遨古科技有限公司. 保留所有权利.</p>
    </footer>
  );
};

export default Footer;