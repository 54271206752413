import React, { Suspense } from 'react';
import { Typography } from 'antd';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useFBX, useAnimations, Environment, Grid, Html, useProgress } from '@react-three/drei';
import { useEffect, useRef } from 'react';
const { Title } = Typography;

// 加载进度条组件
function Loader() {
  const { progress } = useProgress();
  return (
    <Html center>
      <div style={{
        color: '#009CFF',
        fontSize: '1.5em',
        background: 'rgba(255, 255, 255, 0.8)',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center'
      }}>
        加载中... {progress.toFixed(0)}%
      </div>
    </Html>
  );
}

// 3D模型组件
function Model({ url, onLoadAnimations, onPlayAnimation }) {
  const fbx = useFBX(url);
  const modelRef = useRef();
  const { actions, names } = useAnimations(fbx.animations, fbx);
  const [currentAnimation, setCurrentAnimation] = React.useState('');

  // 修改播放动画的函数
  const playAnimation = (animationName) => {
    // 停止当前正在播放的动画
    if (currentAnimation && actions[currentAnimation]) {
      actions[currentAnimation].stop(); // 先停止当前动画
      actions[currentAnimation].fadeOut(0.5);
    }
    
    // 播放新动画
    if (actions[animationName]) {
      actions[animationName].reset().fadeIn(0.5).play();
      setCurrentAnimation(animationName);
      console.log('播放动画:', animationName);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const key = parseInt(event.key);
      const animationNames = names;
      
      if (!isNaN(key) && key > 0 && key <= animationNames.length) {
        playAnimation(animationNames[key - 1]);
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [actions, names, currentAnimation]);

  React.useEffect(() => {
    if (fbx.animations) {
      onLoadAnimations(names);
    }
  }, [fbx.animations, names, onLoadAnimations]);

  React.useEffect(() => {
    if (typeof onPlayAnimation === 'function') {
      onPlayAnimation(playAnimation);
    }
  }, [onPlayAnimation]);

  return <primitive 
    ref={modelRef} 
    object={fbx} 
    scale={0.05}
    position={[0, -3, 0]}
  />;
}

// 3D场景组件
const Scene3D = ({ modelUrl, onLoadAnimations, onPlayAnimation }) => {
  const handlePlayAnimation = onPlayAnimation || (() => {});

  return (
    <div style={{ 
      width: '100%', 
      height: '400px', 
      maxHeight: '60vh'
    }}>
      <Canvas camera={{ position: [0, 2, 5], fov: 90 }}>
        <Suspense fallback={<Loader />}>
          <ambientLight intensity={0.5} />
          <directionalLight position={[5, 5, 5]} intensity={0.3} />
          <Environment files="/assets/textures/studio_small_03_1k.hdr" background scale={0.5} />
          <Grid
            position={[0, -2, 0]}
            args={[20, 20]}
            cellSize={1}
            cellThickness={0.5}
            cellColor="#6f6f6f"
            sectionSize={5}
            fadeDistance={30}
            fadeStrength={1}
          />
          <Model 
            url={modelUrl} 
            onLoadAnimations={onLoadAnimations}
            onPlayAnimation={handlePlayAnimation}
          />
          <OrbitControls minDistance={2} maxDistance={10} />
        </Suspense>
      </Canvas>
    </div>
  );
};

// 主要展示区块组件
const ShowCase3D = () => {
  const [animationList, setAnimationList] = React.useState([]);
  const [playAnimation, setPlayAnimation] = React.useState(() => () => {});
  const modelUrl = 'https://www.dayazhitang.com.cn/models/Running.fbx';

  const handleLoadAnimations = React.useCallback((animations) => {
    setAnimationList(animations);
  }, []);

  const handlePlayAnimation = React.useCallback((callback) => {
    if (typeof callback === 'function') {
      setPlayAnimation(() => callback);
    }
  }, []);

  return (
    <section id="3d-demo" className="py-20">
      <div className="container-xl">
        <h2 className="text-3xl mb-12 font-bold text-center">数字直播间</h2>
        <div className="bg-gradient-to-b from-white to-gray-50 rounded-lg shadow-lg p-8">
          <Scene3D 
            modelUrl={modelUrl} 
            onLoadAnimations={handleLoadAnimations}
            onPlayAnimation={handlePlayAnimation}
          />
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
            {/* 操作说明部分 */}
            <div className="bg-white rounded-lg p-6 shadow-sm">
              <h3 className="text-xl font-bold mb-4 text-blue-600 flex items-center">
                <i className="fas fa-mouse-pointer mr-2" />
                操作说明
              </h3>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-600 rounded-full mr-2"></span>
                  鼠标拖拽：旋转视角
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-600 rounded-full mr-2"></span>
                  鼠标滚轮：缩放画面
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-600 rounded-full mr-2"></span>
                  右键拖拽：平移画面
                </li>
              </ul>
            </div>

            {/* 动画列表部分 */}
            <div className="bg-white rounded-lg p-6 shadow-sm">
              <h3 className="text-xl font-bold mb-4 text-blue-600 flex items-center">
                <i className="fas fa-play-circle mr-2" />
                动画列表
                <span className="text-sm font-normal ml-2 text-gray-500">(按数字键播放)</span>
              </h3>
              <div className="grid grid-cols-2 gap-3">
                {animationList.map((name, index) => (
                  <button 
                    key={name}
                    onClick={() => playAnimation && playAnimation(name)}
                    className="flex items-center p-3 bg-gray-50 hover:bg-blue-50 
                             rounded-md border border-gray-200 transition-colors duration-200"
                  >
                    <span className="w-6 h-6 flex items-center justify-center bg-blue-600 
                                   text-white rounded-full text-sm font-bold mr-2">
                      {index + 1}
                    </span>
                    <span className="text-gray-700 truncate">{name}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShowCase3D;

