import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Reveal from 'reveal.js';
import 'reveal.js/dist/reveal.css';
import 'reveal.js/dist/theme/white.css';

const SmartCampusSlides = () => {
  const revealRef = useRef(null);
  const navigate = useNavigate();
  const [revealHeight, setRevealHeight] = useState(56.25);

  const calculateRevealHeight = () => {
    const width = window.innerWidth;
    return (width * 9) / 16;
  };

  useEffect(() => {
    if (revealRef.current) {
      Reveal.initialize({
        controls: true,
        progress: true,
        center: true,
        hash: false,
        touch: {
          vertical: false,
          horizontal: true
        },
        mouseWheel: false,
        keyboard: {
          27: () => navigate('/'),
          37: 'prev',
          39: 'next',
        },
        embedded: true,
        backgroundColor: 'transparent',
        viewDistance: 4,
        preloadIframes: true,
        previewLinks: false,
      });

      setTimeout(() => {
        Reveal.layout();
      }, 0);
    }

    return () => {
      try {
        if (process.env.NODE_ENV === 'development') {
          return;
        }
        
        if (Reveal) {
          const revealEl = document.querySelector('.reveal');
          if (revealEl) {
            const clone = revealEl.cloneNode(true);
            revealEl.parentNode.replaceChild(clone, revealEl);
          }
        }
      } catch (error) {
        console.warn('Reveal cleanup warning:', error);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newHeight = calculateRevealHeight();
      setRevealHeight(newHeight);
      if (Reveal) {
        Reveal.layout();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const revealStyle = {
    width: '100%',
    height: `min(700px, max(400px, ${revealHeight}px))`,
    backgroundColor: 'transparent',
    position: 'relative',
  };

  return (
    <div className="container mx-auto px-4 py-8 relative">
      <button
        onClick={() => navigate('/')}
        className="absolute top-4 right-8 z-50 bg-white/80 hover:bg-white text-gray-800 px-4 py-2 rounded-full shadow-lg flex items-center space-x-2 transition-all"
      >
        <span>←</span>
        <span>返回首页</span>
      </button>

      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div
          ref={revealRef}
          className="reveal"
          style={revealStyle}
        >
          <div className="slides">
            {Array.from({ length: 30 }, (_, i) => (
              <section key={i + 19} data-preload>
                <img 
                  src={`/smart-campus-ppt/slide${i + 19}.png`} 
                  alt={`Slide ${i + 19}`} 
                  style={{ 
                    width: '100%', 
                    height: 'auto',
                    maxHeight: '90vh',
                    objectFit: 'contain'
                  }} 
                />
              </section>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartCampusSlides;