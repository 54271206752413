import React, { useEffect, useRef } from 'react';

const Map = () => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);

  useEffect(() => {
    window._AMapSecurityConfig = {
      securityJsCode: '4b136ee50b46c223666625af91c7297a',
    };

    const script = document.createElement('script');
    script.src = 'https://webapi.amap.com/maps?v=2.0&key=ea824f775dabe30dd8023533763e1c36';
    script.async = true;
    script.onload = initMap;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      if (mapInstance.current) {
        mapInstance.current.destroy();
      }
    };
  }, []);

  const coordinates = [120.215655,30.186415];
  const initMap = () => {
    if (mapRef.current && window.AMap) {
      mapInstance.current = new window.AMap.Map(mapRef.current, {
        zoom: 15,
        center: coordinates,
      });

      const marker = new window.AMap.Marker({
        position: coordinates,
        title: '杭州遨古科技有限公司',
      });

      mapInstance.current.add(marker);
    }
  };

  return (
    <div 
      ref={mapRef}
      className="w-full h-full"
    />
  );
};

export default Map; 