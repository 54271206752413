import React, { useState } from 'react';

const Patents = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <section id="patents" className="py-20">
      <div className="container-xl">
        <h2 className="text-3xl mb-12 font-bold text-center">知识产权</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            'container.jpg',
            'cloud.jpg',
            'unotify.jpg'
          ].map((image) => (
            <div key={image} className="card cursor-pointer" onClick={() => handleImageClick(image)}>
              <img 
                src={`/patent/${image}`}
                alt={`知识产权 - ${image}`}
                className="w-full h-[400px] object-contain rounded" 
              />
            </div>
          ))}
        </div>
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={handleClose}>
          <div className="relative max-w-4xl max-h-[90vh]">
            <img
              src={`/patent/${selectedImage}`}
              alt={`知识产权 - ${selectedImage}`}
              className="max-w-full max-h-[90vh] object-contain"
            />
            <button
              className="absolute top-4 right-4 text-white text-xl"
              onClick={handleClose}
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Patents;