import React from 'react';

const Hero = () => {
  return (
    <section className="relative min-h-[70vh] md:min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-900 to-gray-800 text-white py-16 md:py-32">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-[url('/assets/hero-bg.jpg')] bg-cover bg-center opacity-20"></div>
      </div>
      <div className="container mx-auto px-4 relative z-10 text-center">
        <h1 className="text-5xl md:text-6xl font-bold mb-8 animate__animated animate__fadeInUp">
          以万物互联为基石<br/>铺就走向虚拟现实之路
        </h1>
        <p className="text-xl md:text-2xl text-gray-300 mb-12 max-w-3xl mx-auto">
          打造创新科技解决方案，连接虚拟与现实。
        </p>
        <a href="#about" className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-4 px-8 rounded-lg transition-colors duration-300 transform hover:scale-105">
          了解更多
        </a>
      </div>
    </section>
  );
};

export default Hero;