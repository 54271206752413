import React from 'react';

const Products = () => {
  return (
    <section id="products" className="py-20">
      <div className="container-xl">
        <h2 className="text-3xl mb-12 font-bold text-center">产品</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          <div className="card">
            <img src="/assets/iot-paas@720x468.png" alt="物联网平台" className="w-full h-auto mb-4 rounded" />
            <h3 className="text-2xl font-bold mb-4">物联网平台</h3>
            <p>基于物联网技术，帮助实现全方位的设备互联和数据管理。</p>
          </div>
          <div className="card">
            <img src="/assets/gameya-live@720x468.png" alt="直播营销互动平台" className="w-full h-auto mb-4 rounded" />
            <h3 className="text-2xl font-bold mb-4">直播营销互动平台</h3>
            <p>通过直播互动提高用户参与度，为企业营销提供强有力的支持。</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;