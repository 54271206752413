import React from 'react';
import { Link } from 'react-router-dom';

const Solutions = () => {
  return (
    <section id="solutions" className="py-20">
      <div className="container-xl">
        <h2 className="text-3xl mb-12 font-bold text-center">解决方案</h2>
        <div className="space-y-8">
          <div className="card">
            <img src="/assets/smart-campus@1280x536.png" alt="智慧校园方案" className="w-full h-auto mb-4 rounded" />
            <h3 className="text-2xl font-bold mb-4">智慧校园方案</h3>
            <p>通过智能技术，为校园提供全面的数字化管理和互动体验。</p>
            <Link 
              to="/smart-campus-slides" 
              className="inline-block mt-4 text-blue-600 hover:text-blue-800 hover:underline"
            >
              查看详细方案演示 →
            </Link>
          </div>
          <div className="card">
            <img src="/assets/smart-home-scene@1200x675.png" alt="智能家居方案" className="w-full h-auto mb-4 rounded" />
            <h3 className="text-2xl font-bold mb-4">智能家居方案</h3>
            <p>打造便捷、高效的智能家居解决方案，提升生活品质。</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solutions; 