import { Layout } from 'antd';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Hero from './components/sections/Hero';
import Products from './components/sections/Products';
import CoreTechnology from './components/sections/CoreTechnology';
import Solutions from './components/sections/Solutions';
import Patents from './components/sections/Patents';
import About from './components/sections/About';
import ShowCase3D from './components/sections/ShowCase3D';
import './App.css';
import './styles/components.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SmartCampusSlides from './components/slides/SmartCampusSlides';

const { Content } = Layout;

function App() {
  useEffect(() => {
    const sections = document.querySelectorAll('section');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('section');
        }
      });
    }, {
      threshold: 0.1
    });

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <Layout className="layout">
            <Header />
            <Content>
              <Hero />
              <CoreTechnology />
              <Products />
              <Solutions />
              <ShowCase3D />
              <Patents />
              <About />
            </Content>
            <Footer />
          </Layout>
        } />
        <Route path="/smart-campus-slides" element={<SmartCampusSlides />} />
      </Routes>
    </Router>
  );
}

export default App; 