import React, { useState } from 'react';
import { FaBox, FaMicrochip, FaLightbulb, FaCertificate, FaUsers, FaBars, FaTimes, FaVideo, FaCaretDown } from 'react-icons/fa';
import HeadTracking from '../sections/HeadTracking';
import { Modal } from 'antd';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAlgoMenuOpen, setIsAlgoMenuOpen] = useState(false);
  const [showHeadTrackingDemo, setShowHeadTrackingDemo] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-gray-900 bg-opacity-90 backdrop-blur-lg">
      <div className="container-xl w-full mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          <div className="text-xl md:text-2xl font-bold text-white">杭州遨古科技有限公司</div>

          {/* 移动端菜单按钮 */}
          <button
            className="md:hidden text-white p-2"
            onClick={toggleMenu}
          >
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>

          {/* 导航菜单 */}
          <nav className={`
            ${isMenuOpen ? 'flex' : 'hidden'} 
            md:flex
            absolute md:relative
            top-20 md:top-0
            left-0 md:left-auto
            right-0 md:right-auto
            bg-gray-900 md:bg-transparent
            flex-col md:flex-row
            p-4 md:p-0
          `}>
            <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
              <li className="relative">
                <div
                  className="text-gray-300 hover:text-white transition-colors flex items-center gap-2 cursor-pointer"
                  onMouseEnter={() => setIsAlgoMenuOpen(true)}
                  onMouseLeave={() => setIsAlgoMenuOpen(false)}
                >
                  <FaMicrochip /> 核心技术 <FaCaretDown />

                  {/* 下拉菜单 */}
                  {isAlgoMenuOpen && (
                    <div className="absolute top-full left-0 bg-gray-900 py-2 min-w-[200px] rounded-md shadow-lg">
                      <div className="px-4 py-2 hover:bg-gray-800">
                        <a href="#CoreTechnology" className="text-gray-300 hover:text-white block">
                          算法介绍
                        </a>
                      </div>
                      <div className="px-4 py-2 hover:bg-gray-800">
                        <div
                          className="cursor-pointer hover:text-white py-1"
                          onClick={() => setShowHeadTrackingDemo(true)}
                        >
                          头部追踪
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </li>
              <li>
                <a href="#products" className="text-gray-300 hover:text-white transition-colors flex items-center gap-2">
                  <FaBox /> 产品
                </a>
              </li>
              <li>
                <a href="#solutions" className="text-gray-300 hover:text-white transition-colors flex items-center gap-2">
                  <FaLightbulb /> 解决方案
                </a>
              </li>
              <li>
                <a href="#3d-demo" className="text-gray-300 hover:text-white transition-colors flex items-center gap-2">
                  <FaVideo /> 数字直播间
                </a>
              </li>
              <li>
                <a href="#patents" className="text-gray-300 hover:text-white transition-colors flex items-center gap-2">
                  <FaCertificate /> 知识产权
                </a>
              </li>
              <li>
                <a href="#about" className="text-gray-300 hover:text-white transition-colors flex items-center gap-2">
                  <FaUsers /> 关于我们
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* 头部追踪演示弹窗 */}
      <Modal
        title="头部追踪算法演示"
        open={showHeadTrackingDemo}
        onCancel={() => setShowHeadTrackingDemo(false)}
        footer={null}
        width={800}
      >
        <HeadTracking />
      </Modal>
    </header>
  );
};

export default Header;