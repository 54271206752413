import React from 'react';

const CoreTechnology = () => {
  return (
    <section id="CoreTechnology" className="py-20">
      <div className="container-xl">
        <h2 className="text-3xl mb-12 font-bold text-center">核心技术</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="card">
            <img src="/assets/ai-media-pipe@720x468.png" alt="数字人及驱动技术" className="w-full h-auto mb-4 rounded" />
            <h3 className="text-2xl font-bold mb-4">数字人及驱动技术</h3>
            <p>探索先进的数字人技术，打造最具互动性和表现力的虚拟体验。</p>
          </div>
          <div className="card">
            <img src="/assets/motion-cap@720x468.png" alt="数字人动作捕捉方案" className="w-full h-auto mb-4 rounded" />
            <h3 className="text-2xl font-bold mb-4">数字人动作捕捉方案</h3>
            <p>精确的动作捕捉技术，支持多平台和不同场景的应用。</p>
          </div>
          <div className="card">
            <img src="/assets/metahuman-live@720x468.png" alt="数字人直播间方案设计" className="w-full h-auto mb-4 rounded" />
            <h3 className="text-2xl font-bold mb-4">数字人直播间方案设计</h3>
            <p>设计独特的虚拟直播间方案，让观众和虚拟主播进行实时互动。</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoreTechnology;
