import React from 'react';
import { Mail, MapPin } from 'lucide-react';
import Map from '../Map';

const About = () => {
  return (
    <section id="about" className="relative w-full bg-gray-50 text-gray-900 overflow-hidden">
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#fff_70%,transparent_110%)]" />
      
      <div className="relative container mx-auto px-4 py-16">
        <div className="grid lg:grid-cols-2 gap-8 items-center">
          <div className="lg:col-span-1 space-y-8">
            <div className="flex items-center space-x-4">
              <img
                src="/logo@512.png"
                alt="遨古科技 Logo"
                className="w-16 h-16 rounded-lg"
              />
              <h2 className="text-3xl font-bold tracking-tighter">关于我们</h2>
            </div>
            
            <div className="prose max-w-none">
              <p className="text-gray-600 leading-relaxed">
                杭州遨古科技有限公司是一家由 AI 驱动研发，专注于物联网和虚拟现实解决方案的科技公司，致力于创新和技术的发展。
              </p>
            </div>

            <div className="space-y-4">
              <div className="flex items-center space-x-3 text-gray-600">
                <Mail className="h-5 w-5 text-blue-600" />
                <a href="mailto:bd@ongo360.com" className="hover:text-blue-600 transition-colors">
                  bd@ongo360.com
                </a>
              </div>
              
              <div className="flex items-center space-x-3 text-gray-600">
                <MapPin className="h-5 w-5 text-blue-600" />
                <span>杭州市滨江区聚业路28号2幢</span>
              </div>
            </div>

            <div className="mt-6">
              <img src="/assets/dd-qrcode@600x600.png" alt="钉钉二维码" className="w-32 h-32" />
            </div>
          </div>
          <Map />
        </div>
      </div>
    </section>
  );
};

export default About;